var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"d-flex"},[(!_vm.showShareLink)?_c(_setup.StyledButton,{staticClass:"simple-model-header-save-btn d-inline-block align-self-center",class:{
      inactive: !_setup.isSavingRef && !_setup.displaySavedText,
    },attrs:{"depressed":"","disabled":_vm.disabledSaveBtn},on:{"click":function($event){return _setup.emit('save')}}},[_vm._v("\n    "+_vm._s(_setup.displaySavedText ? "Saved" : "Saving")+"\n    "),(_setup.isSavingRef && !_setup.displaySavedText)?_c('v-progress-circular',{staticClass:"ml-2",attrs:{"size":12,"width":2,"color":"#009b8e","indeterminate":""}}):(_setup.displaySavedText)?_c('v-icon',{attrs:{"dark":"","right":""}},[_vm._v("mdi-check")]):_vm._e()],1):_vm._e(),_vm._v(" "),(_vm.showShareLink)?_c(_setup.StyledButton,{staticClass:"simple-model-header-share-btn rounded-lg px-2",attrs:{"depressed":"","outlined":""},on:{"click":function($event){_setup.openShareDialog = true}}},[_vm._v("\n    Share your model\n    "),_c('v-icon',{staticClass:"ml-1",attrs:{"x-small":"","right":""}},[_vm._v("mdi-export-variant")])],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"d-inline-block ml-2"},[_c('StyledMenu',{attrs:{"right":"","activator-props":{
        label: '',
        icon: 'mdi-dots-horizontal',
        iconColor: _setup.darkActive ? 'white' : 'grey darken-2',
        rotateIconOnOpen: false,
        class: 'd-inline-block',
      },"dropdown-min-width":"180px"}},[_c('v-list',{staticClass:"options-list elevation-0 rounded-lg pa-1"},[_c('ConfirmationDialog',{attrs:{"title":"Delete Model","confirm-label":"Delete","disabled-btn":false,"manual-hide":true,"show-confirm-loader":_setup.isDeleting,"max-width":480},on:{"confirm":_setup.onDelete},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-list-item',_vm._g({staticClass:"px-2",attrs:{"disabled":!_setup.currentModelId,"dense":""},on:{"click":function($event){$event.preventDefault();}}},on),[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-delete-outline")]),_vm._v("\n              Delete Model\n            ")],1)]}}])},[_vm._v(" "),_c('p',[_vm._v("\n            "+_vm._s(_setup.I18nFn(`Are you sure you want to delete this`))+"\n            "),_c('strong',[_vm._v(_vm._s(_setup.tickersymbol))]),_vm._v("\n            "+_vm._s(_setup.I18nFn(`model?`))+"\n          ")])])],1)],1)],1),_vm._v(" "),(_vm.showShareLink)?_c(_setup.ShareDialog,{key:_setup.isModelPublic,attrs:{"open":_setup.openShareDialog,"url":_setup.publicModelUrl,"ticker-name":_setup.storeTickerSymbol,"company-name":_setup.storeCompanyName,"cid":_setup.storeCid,"tid":_setup.storeTid,"model-id":_setup.currentModelId,"public":_setup.isModelPublic,"data":{
      headerProps: {
        realizedAtDateObj: _vm.data?.realizedAtDateObj,
        potentialPercentage: _vm.data?.potentialPercentage,
        differenceInYears: _vm.data?.differenceInYears,
        realizedAtDate: _vm.data?.realizedAtDate,
        stockPrice: _vm.data?.stockPrice,
        irr: _vm.data?.irr,
        totalStockPrice: _vm.data?.totalStockPrice,
        totalReturn: _vm.data?.totalReturn,
      },
      messageProps: {
        epsCagr: _vm.data?.epsCagr,
        priceOverEarningsMultiple: _vm.data?.priceOverEarningsMultiple,
        dividendYield: _vm.data?.dividendYield,
      },
      chartOptions: _vm.data?.chartOptions,
    },"max-width":"1124","simple":""},on:{"close":_setup.handleClose}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }